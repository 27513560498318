import Shop from '@/api/shop'
import store from '../store/index.js'
const open = function () {
  Shop.init().then(res => {
    // console.log(res)
    if (res.status == 200) {
      let vip = res.result.vipType
      if(vip != 3) {
        // 0 关闭 1 欠费 2 标准的 3企业版
        // 商铺到期 maturity
        if(vip == 1) {
          this.$router.push({
            path: '/maturity'
          })
        }
        // 不支持 upgrade
        if (vip == 2) {
          this.$router.push({
            path: '/upgrade'
          })
        }
      }
      store.commit('setShop', res.result)
    }
  })
}

const getif = function (vip) {
  // console.log(vip)
  if(vip != 3) {
    // 0 关闭 1 欠费 2 标准的 3企业版
    // 商铺到期 maturity
    if(vip == 1) {
      this.$router.push({
        path: '/maturity'
      })
    }
    // 不支持 upgrade
    if (vip == 2) {
      this.$router.push({
        path: '/upgrade'
      })
    }
  }
}

const getChinese = function(str) {
  let data = str.split(', ')
  // console.log(data);
  for (let i in data) {
    // console.log(i);
    switch (data[i]) {
      case 'Bruise':
        data[i] = '淤痕';
        break;
      case 'Cloud':
        data[i] = '云雾';
        break;
      case 'Crystal':
        data[i] = '晶状体';
        break;
      case 'Chip':
        data[i] = '缺口';
        break;
      case 'Cavity':
        data[i] = '洞穴';
        break;
      case 'Knot':
        data[i] = '晶结';
        break;
      case 'Indented Natural':
        data[i] = '内凹天然晶面';
        break;
      case 'Natural':
        data[i] = '原始晶面';
        break;
      case 'Feather':
        data[i] = '羽裂纹';
        break;
      case 'Needle':
        data[i] = '针状物';
        break;
      case 'Laser Drill Hole':
        data[i] = '激光洞';
        break;
      case 'Reflecting Surface Graining':
        data[i] = '反射外部结晶纹';
        break;
      case 'Internal Graining':
        data[i] = '内部结晶纹';
        break;
      case 'Surface Graining':
        data[i] = '外部结晶纹';
        break;
      case 'Manufacturing Remnant':
        data[i] = '生产残留物';
        break;
      case 'Minor Details of Polish':
        data[i] = '细微抛光痕';
        break;
      case 'Pinpoint':
        data[i] = '针点';
        break;
      case 'Twinning Wisp':
        data[i] = '孪晶纹';
        break;
      case 'Extra Facet':
        data[i] = '额外刻面';
        break;
      case 'Etch Channel':
        data[i] = '凹蚀管';
        break;
    }
  }
  // console.log(data);
  return data.join(',')
}

const install = (Vue) => {
  Vue.prototype.$open = open;
  Vue.prototype.$getif = getif;
  Vue.prototype.$getChinese = getChinese;
}

export default {
  install
}
